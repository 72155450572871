<template>
      <v-row>
        <v-col cols="12">
       <v-card flat outlined>
         <v-card-text>
          <v-row dense>
            <v-col cols="12" md="10">
              <v-row dense>
            <v-col cols="12">
              <TextField dense :title="'หมายเลขการเชื่อมต่อ'" :placeholder="'หมายเลขการเชื่อมต่อ'"/>
            </v-col>
             <v-col cols="12">
             <TextField dense :title="'Primary Key'" :placeholder="'Primary Key'"/>
            </v-col>
             <v-col cols="12">
             <TextField dense :title="'Secondary Key'" :placeholder="'Secondary Key'"/>
            </v-col>
              </v-row>
            </v-col>
              <v-col cols="12" md="2" align-self="start">
                <label class="text-title-4 natural--text text--darken-4 pl-5">สถานะการเชื่อมต่อ
                  <v-switch class="pl-5" inset/>
                </label>
              </v-col>
          </v-row>
         </v-card-text>
       </v-card>
        </v-col>
         <v-col cols="12">
       <v-card flat outlined>
         <v-card-text>
        <v-data-table>
          <template slot="top">
              <div class="text-left text-title-1 natural--text text--darken-4">
                ประวัติการเชื่อมต่อ
              </div>
          </template>
          <template slot="no-data">
              <div class="text-center text-title-3 natural--text text--lighten-1 pt-2">
               ไม่มีประวัติการเชื่อมต่อ
            </div>
          </template>
        </v-data-table>
         </v-card-text>
       </v-card>
         </v-col>
      </v-row>
</template>

<script>
import TextField from '@/components/common/TextField'
export default {
  components: {
    TextField
  }

}
</script>

<style>

</style>
